/* Animations */

.RS-animation {
  opacity: 0.005;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  /* transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix)); */
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
}

.RS-animation__slow{
  transition-duration: 1s;
}

.RS-animation.RS-animation--enter {
  opacity: 1;
  /* transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix)); */
  transform: translate3d(0, 0, 0);
}

svg.RS-animation.RS-animation--enter{
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix)); 
}

.RS-animation__text-to-top {
  /* transform: translate3d(0, 8px, 0) rotate(var(--RS-rotate-firefox-fix)); */
  transform: translate3d(0, 8px, 0);
}

.RS-animation__text-to-bottom {
  /* transform: translate3d(0, -13px, 0) rotate(var(--RS-rotate-firefox-fix)); */
  transform: translate3d(0, -13px, 0);
}

.RS-animation__text-to-left {
  /* transform: translate3d(13px, 0, 0) rotate(var(--RS-rotate-firefox-fix)); */
  transform: translate3d(13px, 0, 0);
}

.RS-animation__text-to-right {
  /* transform: translate3d(-8px, 0, 0) rotate(var(--RS-rotate-firefox-fix)); */
  transform: translate3d(-8px, 0, 0);
}


.RS-animation__image-to-right {
  /* transform: translate3d(89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix)); */
  transform: translate3d(89px, -34px, 0);
}

.RS-animation__image-to-left {
  /* transform: translate3d(-89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix)); */
  transform: translate3d(-89px, -34px, 0);
}

.RS-animation__image-to-top {
  /* transform: translate3d(0, 55px, 0) rotate(var(--RS-rotate-firefox-fix)); */
  transform: translate3d(0, 55px, 0);
}

.RS-animation__image-to-bottom {
 /* transform: translate3d(0, -55px, 0) rotate(var(--RS-rotate-firefox-fix)); */
 transform: translate3d(0, -55px, 0);
}

.RS-animation__artwork-content {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: floatArtworkContent 6s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
  
}

.RS-animation__artwork-content--reverse {
  animation-name: floatArtworkContentReverse;
}

@keyframes floatArtworkContent {
  50% {
    transform: translate3d(0, 21px, 0) rotate(var(--RS-rotate-firefox-fix));
  }
}

@keyframes floatArtworkContentReverse {
  50% {
    transform: translate3d(0, -21px, 0) rotate(var(--RS-rotate-firefox-fix));
  }
}

@keyframes floatArtworkShadow {
  50% {
    filter: blur(5px);
  }
}

.RS-stagger--1{
  transition-delay: calc(var(--RS-anim-delay) * 1);
}

.RS-stagger--2{
  transition-delay: calc(var(--RS-anim-delay) * 2);
}

.RS-stagger--3{
  transition-delay: calc(var(--RS-anim-delay) * 3);
}

.RS-stagger--4{
  transition-delay: calc(var(--RS-anim-delay) * 4);
}

.RS-stagger--5{
  transition-delay: calc(var(--RS-anim-delay) * 5);
}

.RS-stagger--6{
  transition-delay: calc(var(--RS-anim-delay) * 6);
}

.RS-stagger--7{
  transition-delay: calc(var(--RS-anim-delay) * 7);
}

.RS-stagger--8{
  transition-delay: calc(var(--RS-anim-delay) * 8);
}

.RS-stagger--connect{
  transition-delay: calc(var(--RS-anim-delay) * 14);
}



.RS-animation__artwork-content--careers__all {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: animArtworkContentCareersAll 3s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top;
}

@keyframes animArtworkContentCareersAll {
  25%{
    transform: translate3d(-5px, -3px, 0) rotateZ(-.5deg) scale(.995);
  }
  75%{
    transform: translate3d(3px, 2px, 0) rotateZ(.5deg) scale(1);
  }
}

.RS-animation__artwork-content--careers__ship {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: animArtworkContentCareersShip .5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
}

@keyframes animArtworkContentCareersShip {
  33%,67%{
    transform: translate3d(0, 2px, 0) scaleY(.99);
  }
}

.RS-anim-ship-lamp{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentShipLamp 4.25s linear infinite;
  opacity: 0;
}

@keyframes animArtworkContentShipLamp {
  50%,70%{
    opacity: 1;
  }
  40%,80%{
    opacity: 0;
  }
}


.RS-anim-chewbacca-eye-left,
.RS-anim-chewbacca-eye-right,
.RS-anim-astro-woman-eye-left,
.RS-anim-astro-woman-eye-right{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentChewbaccaEye 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentChewbaccaEye {
  30%,70%{
    transform: translate3d(0, 0, 0) scaleY(.2);
  }
  25%,35%,65%,75%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-dude-hat{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentDudeHat 3s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
}

@keyframes animArtworkContentDudeHat {
  30%{
    transform: translate3d(1px, -13px, 0) rotateZ(-5deg) scaleX(.98) scaleY(.96) skewX(5deg);
  }
  25%,35%,65%,75%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-dude-mouth{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentDudeMouth 3s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  
}

@keyframes animArtworkContentDudeMouth {
  30%{
    transform: scaleX(.2) scaleY(3);
  }
  25%,35%,65%,75%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-pumpkin-head{
  transform: translate3d(0, 0, 0);
  animation: animPumpkinHead 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  
}

@keyframes animPumpkinHead {
  30%{
    transform: rotateZ(2deg) translateY(1px);
  }
  70%{
    transform: rotateZ(-3deg);
  }
}

.RS-anim-pumpkin-eye-left,
.RS-anim-pumpkin-eye-right{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentPumpkinEye 6.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentPumpkinEye {
  50%,70%{
    opacity: 1;
  }
  40%,80%{
    opacity: 0;
  }
}


.RS-anim-robot-head{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentRobotHead 3.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
}

@keyframes animArtworkContentRobotHead {
  30%{
    transform: translate3d(0px, 5px, 0) scaleX(.99);
  }
  70%{
    transform: translate3d(0px, 8px, 0) scaleX(.98);
  }
  25%,35%,65%,75%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-robot-eye-right{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentRobotEye 3.55s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentRobotEye {
  30%,70%{
    opacity: 1;
  }
  25%,35%,65%,75%{
   opacity: 0;
  }
}

.RS-anim-woman-eye-left,
.RS-anim-woman-eye-right,
.RS-anim-astro-man-eye-left,
.RS-anim-astro-man-eye-right{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentWomanEye 3.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentWomanEye {
  45%,55%{
    transform: translate3d(0, 0, 0) scaleY(.2);
  }
  42.5%,47.5%,52.5%,57.5%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-woman-mouth{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentWomanMouth 4.25s linear infinite;
  
}

@keyframes animArtworkContentWomanMouth {
  30%{
    transform: scaleX(1.1) scaleY(.6);
  }
  25%,35%,65%,75%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-startrek-brow-left,
.RS-anim-startrek-brow-right{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentStartrekBrow 4s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: left;
}

@keyframes animArtworkContentStartrekBrow {
  45%{
    transform: rotateZ(-21deg) scale(.8) translateY(0px);
  }
  30%,60%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-startrek-ear{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentStartrekEar 4s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: left;
}

@keyframes animArtworkContentStartrekEar {
  45%{
    transform: rotateZ(8deg) scale(1.05,1.3);
  }
  30%,60%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-godzilla-scale{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentGodzillaScale 4s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentGodzillaScale {
  45%{
    opacity: 0;
  }
  30%,60%{
    opacity: 1;
  }
}

.RS-anim-godzilla-eye{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentGodzillaEye 4s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentGodzillaEye{
  45%{
    opacity: 1;
    transform: scaleY(2.5);
  }
  30%,60%{
    opacity: 0;
  }
}

.RS-anim-godzilla-hand{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentGodzillaHand 3s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top right;
}

@keyframes animArtworkContentGodzillaHand{
  45%{
    transform: rotateZ(3deg) scaleX(.98) scaleY(.99) translateX(3px);
  }
  30%,60%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-hoverboard-all {
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentHoverboardAll 4s ease infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom left;
  animation-direction: alternate;
}

@keyframes animArtworkContentHoverboardAll {
  0%{
    transform: translate3d(0, 0, 0) rotateX(0deg) scaleY(1);
  }
  100%{
    transform: translate3d(-89px, 5px, 0) rotateX(-5deg); 
  }
}

.RS-anim-bicycle-all {
  transform: translate3d(0, 0, -5px);
  animation: animArtworkContentBicycleAll 4s ease infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  animation-direction: alternate;
  transform-origin: bottom;
}

@keyframes animArtworkContentBicycleAll {
  0%{
    transform: translate3d(0, 0, -120px) rotateX(0deg) scaleY(1);
  }
  100%{
    transform: translate3d(55px, -8px, -120px) rotateX(8deg) scaleY(.995);
  }
}

.RS-anim-bicycle {
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentBicycle .35s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  animation-direction: alternate;
  transform-origin: bottom;
}

@keyframes animArtworkContentBicycle {
  0%,100%{
    transform: translate3d(1px, 1px, 0) scaleY(1);
  }
  33%,67%{
    transform: translate3d(1px, 1px, 0) scaleY(.998);
  }
}

.RS-anim-hoverboard {
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentHoverboard .35s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom left;
}

@keyframes animArtworkContentHoverboard {
  0%,100%{
    transform: translate3d(1px, 1px, 0) skewY(0deg);
  }
  33%,67%{
    transform: translate3d(1px, 1px, 0) skewY(.25deg);
  }
}

.RS-anim-astro-man-brow-left,
.RS-anim-astro-man-brow-right{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroManBrow 4s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: left;
}

.RS-anim-astro-man-brow-right{
  transform-origin: center;
}

@keyframes animArtworkContentAstroManBrow {
  45%{
    transform: rotateZ(-8deg) scale(.8) translateY(3px);
  }
  30%,60%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-astro-woman-brow-left,
.RS-anim-astro-woman-brow-right{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroWomanBrow 3.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: left;
}

.RS-anim-astro-man-brow-right{
  transform-origin: right;
}

@keyframes animArtworkContentAstroWomanBrow {
  45%{
    transform: rotateZ(13deg) scale(1.05) translateY(-1px);
  }
  30%,60%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-astro-man-mouth{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroManMouth 3.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: left;
}


@keyframes animArtworkContentAstroManMouth {
  45%{
    transform: rotateZ(-3deg) scaleX(1.2);
  }
  30%,60%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-astro-woman-mouth{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroWomanMouth 3.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: left;
}


@keyframes animArtworkContentAstroWomanMouth {
  45%{
    transform: rotateZ(3deg) scaleX(.8) scaleY(1.2);
  }
  30%,60%{
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-bicycle-lamp,
.RS-anim-hoverboard-lamp{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroLamp 1.25s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentAstroLamp {
  50%,70%{
    opacity: 1;
  }
  40%,80%{
    opacity: 0;
  }
}

.RS-anim-astro-man-face{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroManFace 3.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentAstroManFace {
  33%{
    transform: rotateZ(-3deg);
  }
  67%{
    transform: rotateZ(5deg);
  }
}

.RS-anim-astro-woman-face{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroWomanFace 3.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentAstroWomanFace {
  33%{
    transform: rotateZ(5deg);
  }
  67%{
    transform: rotateZ(-3deg);
  }
}

.RS-anim-astro-woman-hand-left{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroWomanHandLeft 2.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentAstroWomanHandLeft {
  33%{
    transform: rotateZ(5deg) scaleY(0.95);
  }
  67%{
    transform: rotateZ(-3deg) scaleY(1.05);
  }
}

.RS-anim-astro-man-hand-right{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroManHandRight 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentAstroManHandRight {
  33%{
    transform: rotateZ(-1deg) scaleY(0.9);
  }
  67%{
    transform: rotateZ(1deg) scaleY(1.05);
  }
}

.RS-anim-astro-woman-screen-line{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroWomanScreenLine 1.25s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

.RS-anim-astro-woman-screen-line--second{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .075s);
}

.RS-anim-astro-woman-screen-line--third{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .15s);
}

@keyframes animArtworkContentAstroWomanScreenLine {
  0%{
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  33%{
    transform: scale(1) translateY(5px);
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  67%{
    transform: scale(1) translateY(-3px);
    opacity:0;
  }
  100%{
    opacity:0;
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-astro-woman-screen-rectangle{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroWomanScreenRectangle 2.25s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentAstroWomanScreenRectangle {
  0%{
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  33%{
    transform: scale(.5);
    opacity: 1;
  }
  67%{
    transform: translateX(-28px) translateY(13px) scale(.67);
  }
  100%{
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-arcade-button{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentArcadeButton 1s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
}

@keyframes animArtworkContentArcadeButton {
  20%{
    transform: translateY(5px) scaleY(.9) scaleX(.96);
  }
  40%{
    transform: translate3d(0,0,0);
  }
}

.RS-anim-arcade-button--second{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .125s);
}

.RS-anim-arcade-button--third{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .250s);
}

.RS-anim-arcade-button--fourth{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .375s);
}

.RS-anim-arcade-joystick{
  animation: animArtworkContentArcadeJoystick 1.25s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  transform: rotateZ(-13deg);
}

@keyframes animArtworkContentArcadeJoystick {
  0%{
    transform: rotateZ(-13deg);
  }
  50%{
    transform: rotateZ(13deg);
  }
  100%{
    transform: rotateZ(-13deg);
  }
}

.RS-anim-arcade-stick{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentArcadeStick 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
}

@keyframes animArtworkContentArcadeStick {
  20%{
    transform: rotateZ(0deg);
  }
  60%{
    transform: rotateZ(-55deg);
  }
  10%,30%,40%,70%{
    transform: translate3d(0,0,0);
  }
}

.RS-anim-arcade-hand{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentArcadeHand 1.75s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top left;
}

@keyframes animArtworkContentArcadeHand {
  30%,50%{
    transform: rotateZ(2.5deg) scale(.98);
  }
  70%,80%{
    transform: rotateZ(1.5deg) scale(.97);
  }
  20%,60%,100%{
    transform: translate3d(0,0,0);
  }
}

.RS-anim-arcade-cable{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentArcadeCable 1.75s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentArcadeCable {
  30%,50%,80%{
    opacity: 1;
  }
  20%,60%,100%{
    opacity: 0;
  }
}

.RS-anim-arcade-all{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentArcadeAll 5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentArcadeAll {
  33%{
    transform: translateX(2px) translateY(2px);
  }
  67%{
    transform: translateX(-2px) translateY(-2px);
  }
}

.RS-anim-arcade-screen-thcp{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentAstroWomanScreenThcp 2.25s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentAstroWomanScreenThcp {
  0%{
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  33%{
    transform: translateX(-8px) translateY(-21px) scale(.96);
    opacity: 1;
  }
  67%{
    transform: translateX(5px) translateY(13px) scale(.96);
  }
  100%{
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-arcade-screen-rs{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentArcadeScreenRs 2.25s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  animation-direction: reverse;
}

@keyframes animArtworkContentArcadeScreenRs {
  0%{
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  33%{
    transform: translateX(-8px) translateY(-21px) scale(.96);
    opacity: 1;
  }
  67%{
    transform: translateX(5px) translateY(13px) scale(.96);
  }
  100%{
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-arcade-screen-x{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentArcadeScreenX .75s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentArcadeScreenX {
  0%,100%{
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  50%{
    transform: scale(.9);
    opacity: .9;
  }
}

.RS-anim-handshake-human{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentHandshakeHuman 1s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom right;
  animation-direction: alternate;
}

@keyframes animArtworkContentHandshakeHuman {
  0%{
    transform: translate3d(0, 0, 0);
  }
  100%{
    transform: rotateZ(-2deg) scaleY(.98) translateY(1px);
  }
}

.RS-anim-handshake-robot{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentHandshakeRobot 1s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom left;
  animation-direction: alternate;
}

@keyframes animArtworkContentHandshakeRobot {
  0%{
    transform: translate3d(0, 0, 0);
  }
  100%{
    transform: rotateZ(1deg) translateY(1px);
  }
}

.RS-anim-handshake-hand-diode{
  animation: animArtworkContentHandshakeDiode 3.25s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top;
  opacity: 0;
}

@keyframes animArtworkContentHandshakeDiode {
  50%,70%{
    opacity: 1;
  }
  40%,80%{
    opacity: 0;
  }
}

.RS-anim-handshake-hand-diode.second{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .075s);
}

.RS-anim-handshake-hand-diode.third{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .15s);
}

.RS-anim-handshake-robot-screen-line{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentHandshakeScreenLine 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentHandshakeScreenLine {
  0%,25%,75%,100%{
    transform: translate3d(0, 0, 0);
  }
  50%{
    transform: translateX(21px) translateY(5px);
  }
}

.RS-anim-handshake-robot-screen-dot{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentHandshakeScreenDot 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentHandshakeScreenDot {
  0%,20%,80%,100%{
    transform: scaleY(1);
    opacity: 1;
  }
  40%,60%{
    transform: scaleY(.13);
    opacity: 0;
  }
}

.RS-anim-handshake-robot-screen-dot.third{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .15s);
}

.RS-anim-handshake-robot-screen-dot.fourth,
.RS-anim-handshake-robot-screen-dot.fifth{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .3s);
}

.RS-anim-handshake-robot-cable{
  animation: animArtworkContentHandshakeCable 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentHandshakeCable {
  0%,30%,45%,65%,72.5%,100%{
    opacity: 0;
  }
  35%,40%,67.5%,70%{
    opacity: 1;
  }
}

.RS-anim-journal-portal{
  animation: animArtworkContentJournalPortal 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
}

@keyframes animArtworkContentJournalPortal {
  33%{
    transform: translateY(-5px) scale(.96) rotateZ(-1.5deg);
    opacity: .8;
  }
  67%{
    transform: translateY(0px) scale(.98) rotateZ(1deg);
    opacity: .7;
  }
}

.RS-anim-journal-all-tablets{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentJournalAllTablets 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top center;
}

@keyframes animArtworkContentJournalAllTablets {
  33%{
    transform: translateY(0px) scale(1);
  }
  67%{
    transform: translateY(8px) scale(.98);
  }
}

.RS-anim-journal-tablet {
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentJournalTablet 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  &:nth-child(1) {
    animation-delay: 0.6s;
    animation-direction: reverse;
    animation-name: animArtworkContentJournalTabletAlt;
  }
  &:nth-child(2) {
    animation-delay: calc(var(--RS-anim-intro-artwork-delay) + 0.375s);
  }
  &:nth-child(3) {
    animation-delay: calc(var(--RS-anim-intro-artwork-delay) + 0.525s);
  }
  &:nth-child(4) {
    animation-delay: calc(var(--RS-anim-intro-artwork-delay) + 0.15s);
  }
  &:nth-child(5) {
    animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .075s);
    animation-direction: reverse;
    animation-name: animArtworkContentJournalTabletAlt;
  }
  &:nth-child(6) {
    animation-delay: calc(var(--RS-anim-intro-artwork-delay) + 0.45s);
  }
  &:nth-child(7) {
    animation-delay: calc(var(--RS-anim-intro-artwork-delay) + 0.225s);
    animation-direction: reverse;
    animation-name: animArtworkContentJournalTabletAlt;
  }
}

@keyframes animArtworkContentJournalTablet {
  33% {
    transform: translate3d(0, 5px, 0) rotateZ(2deg) scale(.99);
  }
  67%{
    transform: translate3d(0, -3px, 0) rotateZ(-1deg) scale(1);
  }
}

@keyframes animArtworkContentJournalTabletAlt {
  33% {
    transform: translate3d(0, -1px, 0) rotateZ(-3deg) scale(1);
  }
  67%{
    transform: translate3d(0, 5px, 0) rotateZ(3deg) scale(.97);
  }
}

.RS-anim-journal-finger{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentJournalFinger 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom right;
}

.RS-anim-journal-finger.first{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .15s);
}

.RS-anim-journal-finger.second{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .3s);
}

.RS-anim-journal-finger.third-fourth{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + .45s);
}

@keyframes animArtworkContentJournalFinger {
  33%{
    transform: rotateZ(3deg) scaleY(0.95);
  }
  67%{
    transform: rotateZ(-3deg) scaleY(1.05);
  }
}

.RS-anim-journal-thumb{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentJournalThumb 2s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom right;
}

@keyframes animArtworkContentJournalThumb {
  33%{
    transform: rotateZ(-1deg) scaleY(0.97) scaleX(.99);
  }
  67%{
    transform: rotateZ(1deg) scaleY(1.03) scaleX(1);
  }
}

.RS-anim-journal-heart{
  animation: animArtworkContentJournalHeart 1.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentJournalHeart {
  40%,90%{
    opacity: 0;
  }
  60%,70%{
    opacity: 1;
  }
}

.RS-anim-journal-play{
  animation: animArtworkContentJournalPlay 1s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentJournalPlay {
  50%{
    transform: scale(.85);
  }
}

.RS-anim-journal-arm{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentJournalArm 3s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom center;
}


@keyframes animArtworkContentJournalArm {
  33%{
    transform: translateY(3px) rotateZ(-.5deg) scaleX(.98);
  }
  67%{
    transform: rotateZ(.75deg) scaleX(1);
  }
}

.RS-anim-full-rotate{
  animation: animFullRotate var(--RS-anim-full-rotate-duration) linear infinite;
}

@keyframes animFullRotate {
  100%{
    transform: rotateZ(360deg);
  }
}

.RS-anim-full-counter-rotate{
  animation: animFullCounterRotate var(--RS-anim-full-rotate-duration) linear infinite;
}

@keyframes animFullCounterRotate {
  100%{
    transform: rotateZ(-360deg);
  }
}

.RS-intro-founder-background-orbit:nth-of-type(2).RS-anim-full-rotate,
.RS-intro-founder-background-orbit:nth-of-type(2) > .RS-anim-full-counter-rotate{
  animation-duration: calc(var(--RS-anim-full-rotate-duration) * 1.5);
}

.RS-intro-founder-background-orbit:nth-of-type(3).RS-anim-full-rotate,
.RS-intro-founder-background-orbit:nth-of-type(3) > .RS-anim-full-counter-rotate{
  animation-duration: calc(var(--RS-anim-full-rotate-duration) * 2);
}

.RS-anim-intro-founder-icon-inner{
animation: animIntroFounderIconInner 5s linear infinite;
animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animIntroFounderIconInner {
  50%{
    opacity: .67;
  }
}

.RS-anim-intro-founder-logo-inner{
  animation: animIntroFounderLogoInner 10s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  }
  
  @keyframes animIntroFounderLogoInner {
    50%{
      opacity: .34;
    }
  }


.RS-anim-mech-full{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentMechFull .6s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom center;
}


@keyframes animArtworkContentMechFull {
  33%{
    /* transform: scaleY(.9975) scaleX(1.0035); */
    /* opacity: .5; */
    /* transform: scale(1.0035,.9975); */
    transform: scale(1.0035,.9975);
    /* transform: scale(.9,.9); */
  }
  50%{
    /* transform: scaleY(1) scaleX(1); */
    transform: scale(1,1);
    /* opacity: 1; */
  }
}

.RS-anim-mech-sun{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentMechSun 9s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}


@keyframes animArtworkContentMechSun {
  50%{
    transform: translateY(16%) scale(.9,.92);
    opacity: .9;
  }
}

.RS-anim-mech-bulb{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentMechBulb 6s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentMechBulb {
  50%{
    transform: translateY(-12%) scale(.98) rotateZ(-2deg);
  }
  75%{
    transform: translateY(-6%) scale(.99) rotateZ(1deg);
  }
}

.RS-anim-mech-bulb-light{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentMechBulbLight 6s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentMechBulbLight {
  40%,44%{
    opacity: 0.5;
  }
  38%,42%,52%,56%{
    opacity: 1;
  }
}


.RS-anim-mech-laptop{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentMechLaptop 3s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentMechLaptop {
  50%{
    transform: scale(.995) rotateZ(1deg);
  }
}

.RS-anim-mech-badge-left,
.RS-anim-mech-badge-mid,
.RS-anim-mech-badge-right,
.RS-anim-mech-badge-top,
.RS-anim-mech-badge-bottom{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentMechBadge 5.75s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

.RS-anim-mech-badge-bottom{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + var(--RS-anim-delay)*3);
}

.RS-anim-mech-badge-right{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + var(--RS-anim-delay)*6);
}

.RS-anim-mech-badge-mid{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + var(--RS-anim-delay)*9);
}

.RS-anim-mech-badge-left{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + var(--RS-anim-delay)*12);
}

@keyframes animArtworkContentMechBadge {
  0%,48%,52%,100%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
}

.RS-anim-mech-coffee-mist{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentMechCoffeeMist 3s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top;
  opacity: 0;
}

@keyframes animArtworkContentMechCoffeeMist {
  0%{
    opacity: 0;
  }
  50%{
    transform: scale(.86) translateX(15%);
    opacity: .5;
  }
  100%{
    opacity: 0;
    transform: scale(.76) translateX(30%);
  }
}


.RS-anim-mech-head{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentMechHead 3s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentMechHead {
  33%{
    transform: translateY(-1%) rotateZ(1.5deg);
  }
  67%{
    transform: translateY(1.25%) rotateZ(-2deg);
  }
}


.RS-anim-mech-hand-right-bottom{
  transform: translate3d(0, 0, 0);
  animation: animArtworkContentMechHandRightBottom 1.5s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom right;
}

@keyframes animArtworkContentMechHandRightBottom {
  40%,70%{
    transform: translateY(0%) rotateZ(0deg);
  }
  50%{
    transform: translateY(1%) rotateZ(-13deg) scaleX(.96);
  }
  60%{
    transform: rotateZ(3deg);
  }
}

.RS-anim-mech-knee{
  animation: animArtworkContentMechKnee 4.25s linear infinite;
  opacity: 0;
}

@keyframes animArtworkContentMechKnee {
  50%,70%{
    opacity: 1;
  }
  40%,80%{
    opacity: 0;
  }
}

.RS-anim-mech-helmet-glare{
  animation: animArtworkContentMechHelmetGlare 1s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top right;
}

@keyframes animArtworkContentMechHelmetGlare {
  0%{
    opacity: 0;
    transform: translateX(-25%);
  }
  50%{
    transform: scaleX(.86) translateX(15%);
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: scaleX(.6) translateX(20%);
  }
}

.RS-anim-mech-antenna-first,
.RS-anim-mech-antenna-third{
  animation: animArtworkContentMechAntennaThird 2.75s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom right;
}



@keyframes animArtworkContentMechAntennaThird {
  40%,70%{
    transform: rotateZ(0deg);
  }
  50%{
    transform: rotateZ(-13deg) scaleX(.96);
  }
}

.RS-anim-mech-antenna-second,
.RS-anim-mech-antenna-fourth{
  animation: animArtworkContentMechAntennaFourth 3.75s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom left;
}

@keyframes animArtworkContentMechAntennaFourth {
  40%,70%{
    transform: rotateZ(0deg);
  }
  50%{
    transform: rotateZ(21deg) scaleX(.96);
  }
}

.RS-anim-mech-antenna-third,
.RS-anim-mech-antenna-fourth{
  animation-delay: calc(var(--RS-anim-intro-artwork-delay) + var(--RS-anim-delay)*2);
}


.RS-anim-mech-phone{
  animation: animArtworkContentMechPhone 3s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentMechPhone {
  40%,44%,48%{
    transform: rotateZ(-.5deg) scale(.98);
  }
  50%,54%,58%{
    transform: rotateZ(1deg) scale(.97);
  }
  38%,42%,46%,52%,56%,60%{
    transform: rotateZ(0deg);
  }
}

/* .RS-anim-mech-antenna-fifth{
  animation: animArtworkContentMechAntennaFifth 4s linear infinite;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: left;
}



@keyframes animArtworkContentMechAntennaFifth {
  50%{
    transform: scaleX(.9);
  }
} */


.RS-looped-animation{
  animation-play-state: paused!important;
  backface-visibility: hidden;
  will-change: transform;
}

.RS-looped-animation--running{
  animation-play-state: running!important;
}