/* Fonts */

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('../static/Satoshi-Variable.woff2') format('woff2'),
       url('../static/Satoshi-Variable.woff') format('woff'),
       url('../static/Satoshi-Variable.ttf') format('truetype');
       font-weight: 100 1000;
       font-stretch: 75% 125%;
       font-display: swap;
}