/* Grid */

.RS-section {
  width: 100%;
  /* padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-96);

  @media (--md-viewport) {
    padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-128);
  }

  @media (--lg-viewport) {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-128);
  } */


  /* padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-48);

  @media (--md-viewport) {
    padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-64);
  }

  @media (--lg-viewport) {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-96);
  } */

  padding: 6vh var(--RS-space-24) 8vh;

  @media (--md-viewport) {
    padding: 6vh var(--RS-space-24) 9vh;
  }

  @media (--lg-viewport) {
    padding: 9vh var(--RS-space-32) 12vh;
  }
}


.RS-section-contact{
  @media (--lg-viewport) {
    padding: 18vh var(--RS-space-32) 9vh;
  }
}

.RS-container {
  position: relative;
  max-width: var(--RS-col-8);
  margin: 0 auto;
}

.RS-container.RS-container--narrow {
  max-width: var(--RS-col-4);
}

.RS-section-founder-team .RS-container{
  @media (--lg-viewport) {
    display: flex;
    flex-direction: row-reverse;
    gap: calc(var(--RS-space-96) - var(--RS-space-16));
    align-items: center;
  }
}

.RS-section-founder-blog-showcase .RS-container{
  min-height: 100vh;
}