/* Cookie Bar */

.RS-cookie-bar {
  position: fixed;
  right: var(--RS-space-8);
  bottom: var(--RS-space-8);
  z-index: 666;
  margin: 8px;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, var(--RS-space-32), 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  z-index: 1000000; /* this is to script-embedded overlay Tolstoy widget with 999999 */

  @media (--md-viewport) {
    right: var(--RS-space-32);
    bottom: var(--RS-space-48);
  }
}

.RS-cookie-bar.RS-cookie-bar--visible {
  pointer-events: all;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.RS-cookie-bar__box {
  max-width: 465px;
  padding: var(--RS-space-24) var(--RS-space-16);
  color: var(--RS-white-color);
  background-color: var(--RS-space-grey-color);
  border: 1px solid rgb(0, 0, 0);
  border-radius: 16px;
  box-shadow: 8px 8px 0 0 rgb(0, 0, 0);

  @media (--md-viewport) {
    padding: var(--RS-space-24) var(--RS-space-32);
  }
}

.RS-cookie-bar__content {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 var(--RS-space-8);
}

.RS-cookie-bar__title {
  margin: 0 0 var(--RS-space-8);
  font-size: 32px;
  line-height: 40px;

  @media (--md-viewport) {
    font-size: 40px;
    line-height: 48px;
  }
}

.RS-cookie-bar__title-icon {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  fill: currentColor;

  @media (--md-viewport) {
    top: -2px;
    width: 40px;
    height: 48px;
  }
}

.RS-cookie-bar__description {
  padding: var(--RS-space-8) 0;
  margin: 0 0 var(--RS-space-16);

  @media (--md-viewport) {
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-cookie-bar__actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.RS-cookie-bar__actions > .RS-button{
  width: calc(50% - var(--RS-space-8));
}